<app-nav-header></app-nav-header>
<app-sidebar-menu></app-sidebar-menu>
<ngx-loading [show]="loading">
</ngx-loading>
<div class="page-content-wrapper">
    <div class="page-content">
        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="container">

                    <div class="card card-topline-green">
                        <div class="card-head">
                            <header>Corrida</header>
                        </div>
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Data da Corrida</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ run.date | date:'dd/MM/yyyy' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Solicitação</b>
                                        </div>
                                        <div class="col-md-12">
                                            <a class="btn btn-outline-info"
                                                href="/convenio/covenant-trip-request/{{ run.runRequest.id }}">
                                                #{{
                                                run.runRequest.id }} </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Hora de Início</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ run.startHour }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Hora de Fim</b>
                                        </div>
                                        <div class="col-md-12">
                                            {{ run.endHour }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Status</b>
                                        </div>
                                        <div class="col-md-12">
                                            <span *ngIf="run.runRequest.active"
                                                class="label label-sucess label-mini">Ativo</span>
                                            <span *ngIf="!run.runRequest.active"
                                                class="label label-danger label-mini">Inativo</span>

                                        </div>
                                        <div class="col-md-6">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 class="mt-3">Passageiros</h3>
                            <div class="table-responsive-md mt-3">
                                <table class="table table-striped custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Colaborador</th>
                                            <th>Telefone</th>
                                            <th>Whatsapp</th>
                                            <th>Centro de Custo</th>
                                            <th>Principal</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let passenger of run.runRequest.passengers; let i=index">
                                        <tr>
                                            <td>
                                                {{ passenger.collaborator.name }}
                                            </td>
                                            <td>
                                                {{ passenger.collaborator.phone | mask: '(00) 0000-0000||(00) 0
                                                0000-0000' }}
                                            </td>
                                            <td>
                                                {{ passenger.collaborator.whatsapp | mask: '+00 00 0 0000-0000' }}
                                            </td>
                                            <td>
                                                {{ passenger.collaborator.costCenter }}
                                            </td>
                                            <td>
                                                <span *ngIf="passenger.isMain">Sim</span>
                                                <span *ngIf="!passenger.isMain">Não</span>
                                            </td>
                                            <td>
                                                <a class="btn btn-primary" data-toggle="collapse"
                                                    href="#collapseExample{{i}}" role="button" aria-expanded="false"
                                                    aria-controls="collapseExample">
                                                    Endereço
                                                </a>
                                            </td>
                                        </tr>
                                        <tr class="collapse" id="collapseExample{{i}}">
                                            <td colspan="6" class="hiddenRow">
                                                <div class="card card-body">
                                                    <div class="row">
                                                        <b>Endereço de Origem</b>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="row"><b>CEP</b></div>
                                                            <div class="row">{{ passenger.origin.zipCode }}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <div class="row"><b>Logradouro</b></div>
                                                            <div class="row">{{ passenger.origin.neighborhood }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="row"><b>Bairro</b></div>
                                                            <div class="row">{{ passenger.origin.publicPlace}}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="row"><b>Estado</b></div>
                                                            <div class="row">
                                                                {{ passenger.origin.city.state.name}}</div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="row"><b>Cidade</b></div>
                                                            <div class="row">{{ passenger.origin.city.name}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <!-- <div class="col-md-4">
                                    <a href="/run/edit/{{ run.id }}" class="btn btn-warning">Editar</a>
                                </div> -->
                                <div class="col-md-4">
                                    <a href="/convenio" class="btn btn-info">Voltar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>